@include media-breakpoint-down(md) {
    .card-actions {
        visibility: visible !important;
    }
}

.card {
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-color: var(--card-border-color);
    position: relative;
    border: var(--card-border);
    border-radius: var(--card-border-radius);
    &:hover {
        .card-actions {
            visibility: visible;
        }
    }

    .card-overlay {
        background-color: var(--card-overlay-bg-color);

        &:hover {
            background-color: var(--card-overlay-hover-bg-color);
            cursor: pointer;
        }
    }
}

$image-height: 232.91px;
$image-filter-height: 160px;
$image-width: 160px;

.card-item-container {
    .card {
        max-width: $image-width;
        cursor: pointer;
        padding-left: 0px;
        padding-right: 0px;
        box-sizing: border-box;
        position: relative;
        color: var(--card-text-color);
        border: 1px var(--card-border-color);
        box-sizing: border-box;
    }

    .card-title {
        font-size: 0.85rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        padding: 10px;
    }

    .overlay {
        height: $image-height;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 10;

        &.filter {
            height: $image-filter-height;

            .card-overlay {
                height: $image-filter-height;
            }
        }
    }


    .card-body {
        padding: 0 5px !important;
        background-color: rgba(0,0,0,0.7);
        border-width: var(--card-border-width);
        border-style: var(--card-border-style);
        border-color: var(--card-border-color);
        position: absolute;
        bottom: 0;
        z-index: 115;
        width: 100%;
        bottom: 39px;
        font-size: 0.8rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        max-height: 58px;
        overflow: hidden;
    }

    .card-title {
        width: 100%;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
    }

    .card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $image-height;
        z-index: 10;
        transition: all 0.2s;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
}
